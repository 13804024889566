import error from '../icons/error.png' 

function NotFound() {
    return (
        <div className="text-[#333333] text-center flex-grow mx-auto content-center">
            <img src={error} width="100px" className='mx-auto'/>
            <div className="text-2xl font-semibold">Oops!</div>
            <div className="text-3xl text font-bold">Error 404</div>
            <div className="text-xl italic">Page Not Found</div>
        </div>
    );
  }
  
  export default NotFound;