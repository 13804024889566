import Listening from './listening';

function Home() {
  return (
    <div className="flex-grow">
    {/* <Listening/> */}
    <div>Home</div>
    </div>
  );
}

export default Home;
