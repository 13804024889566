import './App.css'
import {Route,Routes } from 'react-router-dom'
import Home from './pages/home'
import About from './pages/about'
import NotFound from './pages/notfound'
import Header from './pages/header'
import Footer from './pages/footer'
import Library from './pages/library'
import Playlists from './pages/playlists'
import Blog from './pages/blog'

function App() {
  return (
    <div className='flex flex-col min-h-screen'>
    <Header/>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/home" element={<Home />}/>
      {/* <Route path="/blog" element={<Blog />}/> */}
      {/* <Route path="/library" element={<Library />}/> */}
      {/* <Route path="/playlists" element={<Playlists />}/> */}
      <Route path="/about" element={<About />}/>
      <Route path="*" element={<NotFound />}/>
    </Routes>
    <Footer/>
    </div>
  );
}

export default App;