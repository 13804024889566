// Footer.js

const Footer = () => {
    return (
      <footer className="text-center bg-slate-200 text-[#333333] py-2">
        Developed by Vivek Krishna © 2024
      </footer>
    );
  };
  
export default Footer;